import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { closeIcon } from "../../../assets/icons";
import { Button, useLegacySupport } from "../../../components";
import moment from "moment";
import { infinityLoader } from "../../../config/Images";
import { t } from "i18next";
import EngagmentSelectPicker from "../../case-summary/components/EngagmentSelectPicker";


// Functional component for Information Governance
const BulkActionPopup = (props: any) => {
    const legacySupport = useLegacySupport();

    // State for storing the "involve case" data
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const formURL = props?.data?.popupForm?.controls?.find((item: any) => item.name === "SaveButton")?.addRecordApiEndPoint.endpointUrl

    // State for storing form data
    const formObj: any = {};
    props?.data?.popupForm?.controls?.forEach((element: any) => {
        formObj[element.name] = element?.value === undefined ? "" : element.name === "ContactDate" || element.name === "CommitmentWeWillStartDate" || element.name === "CommitmentYouWillStartDate" ? moment(element.value, 'MM/DD/YYYY h:mm:ss A').format('DD/MM/YYYY') : element.value;
    });

    const [formData, setFormData] = useState<any>(formObj);


    // useEffect(() => {
    //     setIsFetching(false);
    // }, [props.errorFlag]);

    // Function to call API and handle redirection
    const callApiAndRedirect = (e: any): void => {
        if (Object.values(formData).some(a => a !== '')) {
            setIsFetching(true);
            console.log("formData", formData);
            props.onSubmit("formSubmit", formData, formURL);
        } else {
            toast.error("Please select values");
        }
    }
    
    useEffect(() => {
        if (!Object.values(formData).some(a => a !== '')) {
            setIsEdit(true);
        }
    }, []);

    const handleDropdownChange = (e: any) => {
        const { name, value } = e;
        setFormData({ ...formData, [name]: value });
    };

    // Function to render layout based on item type
    const __renderLayout = (items: any, index: any) => {
        let itemsToRender = [];
        switch (items.type) {
            
            case "selectpicker":
                itemsToRender.push(
                   <EngagmentSelectPicker
                            formData={formData}
                            value={formData[items.name]}
                            hidden={!items.visible}
                            token={legacySupport.token}
                            dspId={props.dspId}
                            items={items}
                            handleChange={handleDropdownChange}
                        /> 
                );

                break;
            default:
                return null;
        }

        return itemsToRender;
    };

    // Render delete confirmation popup
    const __renderDeleteConfirmation = () => {
        return (
            <div className="delete-confirmation">
                <p className="confirmation-text">Are you sure you want to delete the selected records?</p>
                <p className="confirmation-subtext">This action cannot be undone.</p>
            </div>
        );
    };

    return (
        props?.data?.popupForm?.allowAdd == true || !isEdit || props?.data?.isDeleteConfirmation ?
            <div className="xan-modal">
                <div
                    className="modal fade show"
                    id="exampleModal"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">{props.title}</h5>
                                <button className="btn btn-transparent close" onClick={() => {
                                    props.onSubmit("", null);
                                }} >
                                    <img src={closeIcon} alt="close" />
                                </button>
                            </div>
                            <form
                                className="information-governance-container"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    callApiAndRedirect(e);
                                }}
                            >
                            <div className="modal-body">
                                {props?.data?.isDeleteConfirmation ? 
                                    __renderDeleteConfirmation() :
                                    <>
                                        {props?.data?.popupForm === undefined && <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div>}
                                        
                                            <div className="content-container">
                                                <div className="row">
                                                    {props?.data?.popupForm?.controls?.map((items: any, index: any) => {
                                                        return __renderLayout(items, index);
                                                    })}
                                                </div>
                                            </div>
                                        
                                    </>
                                }
                            </div>
                            <div className="modal-footer">
                                    <Button
                                        key={""}
                                        title={"Cancel"}
                                        className="button cancel-button"
                                        type="button"
                                        onClick={() => props.onSubmit("", null)}
                                    />
                                
                                <Button
                                    key={""}
                                    title={props?.data?.isDeleteConfirmation ? "Confirm" : 
                                           isFetching ? t("REQUESTING") : 
                                           props?.data?.popupForm?.controls?.find((item: any) => item.name === "SaveButton")?.label}
                                    name={props?.data?.isDeleteConfirmation ? "confirm" :
                                         props?.data?.popupForm?.controls?.find((item: any) => item.name === "SaveButton")?.label}
                                    className={
                                        "button save-button " +
                                        (isFetching ? "requesting btn-disable" : "") +
                                        (props?.data?.isDeleteConfirmation ? " delete-confirm" : "")
                                    }
                                    type="submit"
                                    onClick={props?.data?.isDeleteConfirmation ? 
                                           () => props.onSubmit("formSubmit", {}) : 
                                           callApiAndRedirect}
                                    disabled={(!props?.data?.isDeleteConfirmation && props.isEditable) || isFetching}
                                />
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> : null
    );
};

export default BulkActionPopup;

