// Import React hooks and Kendo Grid components
import {
    Grid, GridColumn, GridPageChangeEvent, GridSortChangeEvent, GridToolbar, GridSelectionChangeEvent,
    GridHeaderSelectionChangeEvent,
    getSelectedState,
    GridExpandChangeEvent,
    GridCellProps,
    GridCustomCellProps
} from "@progress/kendo-react-grid"
import { useEffect, useState, useCallback, useRef } from "react";

import { SortDescriptor, filterBy, getter, orderBy } from "@progress/kendo-data-query";
import { PagerTargetEvent } from "@progress/kendo-react-data-tools";

import { infinityLoader } from "../../config/Images";
import { toast } from "react-toastify";
import { Input } from "@progress/kendo-react-inputs";

// Interface to type-check sort states
const initialSort: Array<SortDescriptor> = [
    { field: "id", dir: "asc" },
];

const DATA_ITEM_KEY: string = "id";
const SELECTED_FIELD: string = "SELECTED_FIELD";
const EDITFIELD: string = "inEdit";

const idGetter = getter(DATA_ITEM_KEY);




export interface kendoGridProps {
    gridData: any;

    isExport: boolean;

    isSelectable: any;
    selectedField: string;
    isFilterable: boolean;
    filtersList: any;

    isPaginated: boolean;
    pageSize?: number;

    expandField?: string;
    onExpand: (e: any) => void;

    // custom action layout for edit, details etc
    isCustomAction: boolean;
    customeActionLayout: (e: any) => any;
    onSelectionChange?: (e: any) => void;
    customCell?: (e: any) => any;

    isShowAll?: boolean;
}


/**
 * KendoGrid component to handle displaying grid data.
 *
 * @param {any} props - The props passed from the parent component.
 * @returns {JSX.Element} - The rendered component.
 */
const KendoGrid: any = (props: kendoGridProps) => {

    const minGridWidth = useRef(0);
    const grid = useRef<any>(null);


    // set minVisibleColumnCount based on window width
    const width = window.innerWidth * 0.2;
    const windowWidth = (window.innerWidth - (width > 360 ? width : 360));

    const propsPageSize = props.pageSize || 10;

    const isSelectable = props.isSelectable || "";
    const selectedField = props.selectedField || "";
    const customCell: any = props.customCell || {};
    const isShowAll: boolean = props.isShowAll || false;

    // const _grid = useRef<any>(null);
    const _export = useRef<any>();

    // State management
    const [gridData, setGridData] = useState<any>(null);
    const [expandedRows, setExpandedRows] = useState<any>([]);
    const [isPaginated, setIsPaginated] = useState<any>(props.isPaginated);

    const [isHiddenColumn, setIsHiddenColumn] = useState<boolean>(false);
    const [hidenColumnCount, setHidenColumnCount] = useState<number>(0);
    const [autoWidth, setAutoWidth] = useState<boolean>(false);
    const [selectedState, setSelectedState] = useState<{ [id: string]: boolean | number[]; }>({});
    const [minVisibleColumnCount, setMinVisibleColumnCount] = useState(0);

    const [filter, setFilter] = useState<any>();

    const initialDataState = {
        skip: 0,
        take: propsPageSize,
    };

    const [dataState, setDataState] = useState<any>(initialDataState);

    const [sort, setSort] = useState(initialSort);

    const [pageSize, setPageSize] = useState<number>(propsPageSize);

    // Event handler for pagination change
    const pageChange = (event: GridPageChangeEvent) => {
        const targetEvent = event.targetEvent as PagerTargetEvent;
        const take = event.page.take;

        if (targetEvent.value) {
            setPageSize(targetEvent.value);
        }

        setDataState({
            ...event.page,
            take,
        });

        // removed server side paging 
        // props.onPageChange(event);
    };


    useEffect(() => {
        grid.current = document.querySelector('.k-grid');
        document.addEventListener("asideToggle", (e) => __triggerAsideToggle());
        document.addEventListener("exportData", (e) => exportExcel());
        document.addEventListener("expandColumns", (e) => setIsHiddenColumn(isHiddenColumn => !isHiddenColumn));

        // return document.removeEventListener("asideToggle", (e) => __triggerAsideToggle);
    }, [])


    // Data processing useEffect
    useEffect(() => {
        if (props.gridData !== null) {

            // if (props?.gridData?.gridColumns?.length > minVisibleColumnCount) {

            let initialWidth = 0;
            let minVisibleColumnCountLocal = 0;
            let visibleColumnIndices;

            visibleColumnIndices = props?.gridData?.gridColumns
                .map((item: any, index: number) => {
                    if (!item.isVisible) {
                        return null
                    }
                    initialWidth = initialWidth + item.width;

                    if (initialWidth <= windowWidth) {
                        minVisibleColumnCountLocal++
                    }

                    return index;
                })
                .filter((index: any) => index !== null);

            if (minVisibleColumnCountLocal === visibleColumnIndices.length) {
                setAutoWidth(true);
            }

            if (!isShowAll) {
                setMinVisibleColumnCount(minVisibleColumnCountLocal);
                if (visibleColumnIndices.length > minVisibleColumnCountLocal) {
                    const hiddenColumnsCount = visibleColumnIndices.length - minVisibleColumnCountLocal;
                    setHidenColumnCount(hiddenColumnsCount);
                    setIsHiddenColumn(true)
                }
                else {
                    setHidenColumnCount(0);
                    setIsHiddenColumn(false)
                }
            } else {
                setHidenColumnCount(0);
                setIsHiddenColumn(false)
            }
            // }



            setGridData(props.gridData);

            if (isPaginated !== props.isPaginated) {
                setIsPaginated(props.isPaginated);
            }


        }
    }, [props]);

    const __triggerAsideToggle = () => {
        setIsHiddenColumn(false)
    }

    const _customActions = (res: GridCellProps) => {
        if (props.isCustomAction !== null && props.isCustomAction === false) {
            return null;
        }

        return (<td>
            <div>{props.customeActionLayout(res.dataItem)}</div>
        </td>)
    }

    const __inputFilters = (e: any) => {
        const value = e.target.value;
        const newFilter: any = {
            logic: "or",
            filters: []
        };

        props.gridData.gridColumns?.map((item: any) => {
            newFilter.filters.push({
                field: item.name.trim(),
                operator: "contains",
                value: value
            })
        });

        setGridData({
            ...gridData,
            gridRows: filterBy(props.gridData.gridRows, newFilter)
        })
    }




    const __renderGridToolbar = () => {
        return <GridToolbar>
            {
                props.filtersList ?
                    <div style={{ flexGrow: 1 }}>
                        <Input
                            placeholder="Filter"
                            onChange={(e) => __inputFilters(e)} />
                    </div> : null
            }
            {/*
                <div className="export-btns-container">
                    <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => exportExcel()}>Export to Excel</button>
                </div>
                <div className="pr-1 d-inline-block"></div>
                <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => { }}>Select</button>
                <div className="pr-1 d-inline-block"></div>
            */}

            {isShowAll || autoWidth ? null :
                <div>
                    <button className="btn btn-primary btn-sm btn-inline-block" onClick={() => {
                        setIsHiddenColumn(!isHiddenColumn);
                    }}>{isHiddenColumn ? "+" : "-"}</button>
                </div>}
        </GridToolbar>
    }

    const __onSelectionChange = useCallback(
        (event: GridSelectionChangeEvent) => {

            const newSelectedState: any = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });

            setSelectedState(newSelectedState);


            if (props.onSelectionChange) {
                props.onSelectionChange(event.dataItem);
            }

        },
        [selectedState]
    );

    const __onHeaderSelectionChange = useCallback(
        (event: GridHeaderSelectionChangeEvent) => {
            const checkboxElement: any = event.syntheticEvent.target;
            const checked = checkboxElement.checked;
            const newSelectedState: any = {};

            event.dataItems.forEach((item) => {
                newSelectedState[idGetter(item)] = checked;
            });
            setSelectedState(newSelectedState);
        },
        []
    );

    const exportExcel = () => {
        if (_export.current !== null) {
            _export.current.save();
        }
    };

    const __renderColumns = () => {

        // Render selected field column
        const columns: any = [];
        if (props.selectedField && props.selectedField !== "") {
            columns.push(<GridColumn
                key="selectedField"
                field={selectedField} filterable={false} width={40} />)
        }

        // Render custom action column
        if (props.isCustomAction && props.isCustomAction === true) {
            columns.push(<GridColumn cell={_customActions}
                filterable={false}
                key="customeAction"
                title="Actions" width={100} />)
        }

        props.gridData.gridColumns.map((tdCol: any, index: number) => {
            if (tdCol.isVisible) {
                let grid = null;
                if (typeof customCell === "function" && customCell()[tdCol.name.trim()]) {
                    grid = <GridColumn
                        key={`column_name_${tdCol.name.trim()}`}

                        title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                        width={tdCol.width === 0 ? "200px" : tdCol.width}

                        cell={customCell()[tdCol.name.trim()]}
                    // columnMenu={() => <GridColumnMenuCheckboxFilter {...props} field={tdCol.name.trim()}  data={gridData} expanded={true}/>}
                    // editable={["CaseOfficer", "CaseStatus"].indexOf(tdCol.name) > -1 ? true : false}
                    />
                } else {
                    // optimization for hidden columns

                    const newProps: any = {};

                    if (!autoWidth) {
                        newProps.width = tdCol.width === 0 ? "auto" : tdCol.width;
                        newProps.minResizableWidth = tdCol.width === 0 ? "auto" : tdCol.width;
                    }


                    grid = <GridColumn
                        key={`column_name_${tdCol.name.trim()}`}
                        field={tdCol.name.trim()}
                        title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                        // minResizableWidth={tdCol.width === 0 ? "auto" : tdCol.width}

                        {...newProps}

                    // columnMenu={() => <GridColumnMenuCheckboxFilter {...props} field={tdCol.name.trim()}  data={gridData} expanded={true}/>}
                    // editable={["CaseOfficer", "CaseStatus"].indexOf(tdCol.name) > -1 ? true : false}
                    />
                }

                if (isHiddenColumn) {
                    if (index <= minVisibleColumnCount) {
                        columns.push(grid)
                    }
                } else {
                    columns.push(grid)
                }
            }
        })
        return columns
    }


    const __expandChange = (event: GridExpandChangeEvent) => {
        if (event.dataItem !== "") {
            props.onExpand(event.dataItem)

            if (!expandedRows.includes(event.dataItem?.Group_Ref)) {
                setExpandedRows((expandedRows: any) => [...expandedRows, event.dataItem?.Group_Ref])
            }
        }
    }



    const __onDataStateChange = (e: any) => {
        setDataState(e.dataState);
    }


    const __renderShowDetailsColumns = (res: any) => {
        const columns: any = []

        res?.gridColumns?.map((tdCol: any, index: number) => {

            // optimization for hidden columns
            const grid = <GridColumn
                key={`column_name_${tdCol.name.trim()}`}
                field={tdCol.name.trim()}
                title={tdCol.displayName === "" ? tdCol.name : tdCol.displayName}
                width={tdCol.width === 0 ? "auto" : tdCol.width}
            // editable={["CaseOfficer", "CaseStatus"].indexOf(tdCol.name) > -1 ? true : false}
            />
            columns.push(grid)

        })

        return columns
    }



    const __showDetails = (props: GridCustomCellProps) => {
        if (props.dataItem === null || props.dataItem === undefined) {
            return null
        }
        return <div>
            <Grid
                key={"childdata"}

                // {...props}
                data={props?.dataItem?.details?.gridRows}
                resizable={true}
            >
                {__renderShowDetailsColumns(props.dataItem.details)}
            </Grid>
        </div>

    }

    const __onExportComplete = (e: any) => {
        toast.success("Data exported successfully")
    }

    const __getData = () => {
        let data = orderBy(filterBy(gridData?.gridRows.map((item: any) => ({
            ...item, [selectedField]: selectedState[idGetter(item)],
        })), filter), sort)

        let paginate = data?.slice(dataState.skip, (dataState.take + dataState.skip))

        return paginate
    }


    const __grid = () => {
        return (<Grid
            data={__getData()}

            columnVirtualization={false}

            style={{ maxHeight: "65vh", width: "100%" }}

            skip={dataState?.skip}
            take={dataState.take}
            total={gridData?.gridRows?.length}
            pageSize={pageSize}
            pageable={isPaginated ? {
                buttonCount: 10,
                pageSizes: [10, 25, 50, 100],
                pageSizeValue: pageSize,
            } : false}
            onPageChange={pageChange}

            filterable={props.isFilterable || false}
            filter={filter}
            onFilterChange={(e) => setFilter(e.filter)}
            // onScroll={scrollHandler}
            // fixedScroll={true}

            selectable={isSelectable}

            dataItemKey={DATA_ITEM_KEY}
            selectedField={selectedField !== "" ? selectedField : ""}
            onSelectionChange={__onSelectionChange}
            onHeaderSelectionChange={__onHeaderSelectionChange}

            resizable={true}
            sortable={true}
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => setSort(e.sort)}

            detail={(res: any) => __showDetails(res)}
            expandField={props.expandField || ""}
            onExpandChange={(e) => __expandChange(e)}

        // scrollable={true}
        // {...dataState}
        // editField={EDITFIELD}
        >                        {/* Render columns */}
            {__renderColumns()}
        </Grid>)
    }

    const __renderGrid = () => {

        if (gridData?.gridRows?.length === 0) {
            return <div className="p-2">{props?.gridData?.emptyMessage}</div>
        }

        return __grid()

    }


    if (gridData === null) {
        return <div className="loading-data"><img src={infinityLoader} alt="Loading" /></div>;
    }

    // Main component render
    return (
        <div className="grid-wrapper">
            {/* Render toolbar  */}
            {__renderGridToolbar()}

            {/* {props.isExport ?
                <ExcelExport
                    data={(gridData?.gridRows?.filter((item: any) => {
                        return selectedState[idGetter(item)]
                    }))}
                    onExportComplete={(e) => __onExportComplete(e)}
                    fileName="case-data.xlsx"
                    ref={_export}
                /> : null} */}


            {__renderGrid()}

        </div>
    )
}

export default KendoGrid;