import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { closeIcon } from "../../../assets/icons";
import { Button, CustomCellDownloads, FileUpload, FormWrapper, TextArea, TextField } from "../../../components";
import { addEngagmentData, downloadAssets, editEngagmentData, resetAddEngagmentData, resetEditEngagmentData } from "../store/actions";
import moment from "moment";
import { infinityLoader } from "../../../config/Images";
import EngagmentSelectPicker from "./EngagmentSelectPicker";
import { useTranslation } from "react-i18next";
import { API_ENDPOINT } from "../../../config/ApiConstants";
import { encodeFormData, getHeaders } from "../../../utils/commonUtils";
import { deleteRequest, resetDelete } from "../store/delete-document.slice";
import { fetchAllDocRequest } from "../store/fetch-all-document.slice";

// Functional component for Information Governance
const AddEngagmentPopup = (props: any) => {
    const { t } = useTranslation()
    const dispatch = useDispatch();

    const isEdit = props.data.selectedRecord === null;

    const addEngagmentPopupData = useSelector((state: any) => state.addEngagmentCM);
    const editPopupEngagmentData = useSelector((state: any) => state.editEngagmentDataCM);
    const deleteDocData = useSelector((state: any) => state.deleteDocData);

    // State for storing the "involve case" data
    const [isFetching, setIsFetching] = useState<boolean>(false);
    const [deleteRequests, setDeleteRequests] = useState<any>([]);

    const [childPicklistControlIds, setChildPicklistControlIds] = useState<any>(null);
    const [childPicklistControlName, setChildPicklistControlName] = useState<any>(null);

    // State for storing form data

    const processData = () => {
        const formObj: any = {};
        props?.data?.popupForm?.controls?.forEach((element: any) => {
            let value = element.value || "";

            if (element.type === "datepicker") {
                if (element.value !== "" && element.value !== undefined) {
                    value = moment(element.value, 'MM/DD/YYYY').format('DD/MM/YYYY');
                } else {
                    value = moment(new Date()).format('DD/MM/YYYY');
                }
            }

            formObj[element.name] = value;
        });

        return formObj
    }
    const [formData, setFormData] = useState<any>(processData);
    const [selectedFiles, setSelectedFiles] = useState<any>([]);



    useEffect(() => {
        setIsFetching(false);
    }, [props.errorFlag]);

    useEffect(() => {
        try {
            if (addEngagmentPopupData.isSuccess && addEngagmentPopupData.data !== null) {


                handleFileChange(addEngagmentPopupData.data);

                toast.success("Case Details added successful");
                props.onSubmit("", null)

                dispatch(resetAddEngagmentData());
            } else if (addEngagmentPopupData.isError) {
                toast.error(addEngagmentPopupData?.data?.error);
                dispatch(resetAddEngagmentData());
            }

            if (editPopupEngagmentData.isSuccess && editPopupEngagmentData.data !== null) {

                handleFileChange(editPopupEngagmentData.data);

                if (deleteRequests.length > 0) {
                    deleteAttachment();
                }

                toast.success("Case Details edit successful");
                props.onSubmit("", null)

                dispatch(resetEditEngagmentData());
            } else if (editPopupEngagmentData.isError) {
                props.onSubmit("", null)
                toast.error(editPopupEngagmentData?.data?.error);
                dispatch(resetEditEngagmentData());
            }


            if (deleteDocData.isSuccess && deleteDocData.data !== null) {
                toast.success("File delete succssful");

                const obj: any = {
                    token: props.token,
                    recordId: formData.Id,
                    dspId: props.dspId
                };

                props.onSubmit("", null)

                dispatch(fetchAllDocRequest(obj));
                dispatch(resetDelete());
            } else if (deleteDocData.isError) {
                toast.error(deleteDocData?.data?.error);

                props.onSubmit("", null)
                dispatch(resetDelete());
            }



        } catch (e: any) {
            toast.error(e.message);
        }
    }, [addEngagmentPopupData, editPopupEngagmentData, deleteDocData, dispatch]);


    // Function to call API and handle redirection
    const handleFormSubmit = (e: any): void => {
        e.preventDefault();
        if (Object.values(formData).some(a => a !== '')) {
            try {

                /**
                 * Dispatch action to Add outcome data
                 **/
                let convertedArray: any = [];
                for (const key in formData) {
                    if (formData[key] !== '' && typeof formData[key] === "string") {
                        const recordDetails = props.data.popupForm.controls.find((item: any) => item.name === key ? item : null);

                        if (recordDetails !== null && recordDetails.isEditable !== false) {
                            if (props.data.selectedRecord !== null) {
                                // Skip if value is same as previous
                                if (props.data.selectedRecord[key] !== formData[key]) {
                                    convertedArray.push({
                                        "controlName": key,
                                        "values": formData[key]
                                    })
                                }
                            } else {
                                convertedArray.push({
                                    "controlName": key,
                                    "values": formData[key]
                                })
                            }
                        }

                    }
                }


                const params: any = {
                    token: props.token,
                    param: {
                        dspId: props.dspId,
                        oneViewReference: props.oneViewReference,
                        controlId: props.data.popupForm.controls[0].associatedControlId,
                    },
                    body: convertedArray,
                }

                if (props.data.selectedRecord === null) {
                    setIsFetching(true);
                    // Add case record
                    dispatch(addEngagmentData(params));
                } else {
                    // Edit case record
                    params.param['id'] = props.data.selectedRecord.linkDataId;

                    if (params.body.length > 0) {
                        setIsFetching(true);
                        dispatch(editEngagmentData(params));
                    } else {
                        let hasFiles = Object.keys(selectedFiles).find((key) => {
                            return selectedFiles[key].length > 0 ? true : false
                        });

                        if (hasFiles !== undefined && hasFiles !== null) {
                            setIsFetching(true);
                            handleFileChange(params.param);
                        }


                        if (deleteRequests.length > 0) {
                            setIsFetching(true);
                            deleteAttachment();
                        }
                    }
                }

            } catch (e: any) {
                toast.error(e.message);
            }
        } else {
            toast.error("Please select values");
        }
    }

    const handleChange = (e: any) => {
        const { name, value } = e;
        setFormData({ ...formData, [name]: value });
    };

    const handleDropdownChange = (e: any) => {
        const { name, value } = e;
        setFormData({ ...formData, [name]: value });
    };

    const handleDateChange = (e: any) => {
        const { name, value } = e;
        setFormData((prevState: any) => ({ ...prevState, [name]: value === "" ? "" : moment(value, 'YYYY-MM-DD').format('DD/MM/YYYY') }));
    };

    const handleChangeFilePciker = (e: any, name: string) => {
        setSelectedFiles((prevState: any) => ({ ...prevState, [name]: e }));
    };

    useEffect(() => {
        if (props.isFetching !== isFetching) {
            setIsFetching(props.isFetching);
        }
    }, [props.isFetching]);




    const handleFileChange = (data: any) => {
        for (const key in selectedFiles) {
            if (selectedFiles[key] !== '' && typeof selectedFiles[key] === "object") {
                onSuccessFullyUploaded(selectedFiles[key], key, data);
            }
        }
    }

    const onSuccessFullyUploaded: any = (files: any, key: string, data: any) => {
        try {
            files.map((file: any, index: any) => {
                if (file.isUploaded) {
                    toast.info(`Skipping ${file.attachment.name} as it is already uploaded`);
                    return;
                }

                const fmData = new FormData();
                fmData.append("file", file.attachment);

                files[index].isUploading = true;
                setSelectedFiles((prevState: any) => ({ ...prevState, [key]: files }));

                const params: string = encodeFormData({ controlId: data.controlId, dspId: data.dspId, recordId: data.id, oneViewReference: props.oneViewReference })

                //   fetch(props.uploadURL, {
                fetch(API_ENDPOINT + '/case-details/upload-document?' + params, {
                    method: "POST",
                    headers: {
                        ...getHeaders(props.token)
                    },
                    body: fmData,
                }).then((response) => {
                    if (response.ok) {
                        files[index].isUploading = false;
                        files[index].isUploaded = true;
                        setSelectedFiles((prevState: any) => ({ ...prevState, [key]: files }));

                        props.onSubmit("", null);
                        toast.success(`${file.name.length > 20 ? file.name.slice(0, 20) + "... ." + file.name.slice(-3) : file.name} uploaded successfully`);

                        props.onSubmit("", null)
                    } else {
                        files[index].isUploading = false;
                        files[index].isError = true;
                        setSelectedFiles((prevState: any) => ({ ...prevState, [key]: files }));

                        toast.error("Error in uploading files");
                        props.onSubmit("", null)
                    }
                }).catch((error) => {
                    toast.error("Error in uploading files");
                });

            });
        } catch (e: any) {
            console.log(e.message);
        }
    }

    const __deleteAttachment = (item: any) => {
        setDeleteRequests([...deleteRequests, item.docId]);
    }

    const deleteAttachment = () => {
        deleteRequests.forEach((item: any) => {
            const obj: any = {
                token: props.token,
                params: {
                    dspId: props.dspId,
                    docId: item
                }
            };

            dispatch(deleteRequest(obj));
        });
    }

    const __downloadAsset = (url: string) => {
        try {
            dispatch(downloadAssets({
                token: props.token,
                url: url
            }))
        } catch (e: any) {
            toast.error(e.message);
        }
    }

    const __renderFileLinks = (items: any) => {
        if (items === undefined || items.length === 0) {
            return null
        }

        return (<>
            <h6 className="involve-case-title">Uploaded Documents</h6>

            <CustomCellDownloads
                data={items}
                showAll={true}

                isDelete={true}
                caseId={formData.Id}
                dspId={props.dspId}

                onDelete={(item: any) => __deleteAttachment(item)}

                onDownload={(link: any) => __downloadAsset(link)}
            />
        </>)
    }
 
    // Function to render layout based on item type
    const __renderLayout = (items: any, index: any, type:any) => {
        if (items.visible === false) {
            return;
        }

        let limit = props?.data?.popupForm?.controls.length < 8;
        let columnsClassess = !limit ? "col-6 pb-4" : "col-12  pb-4";

        let itemsToRender = [];
        switch (items.type) {
            case "selectparentpicker":
                itemsToRender.push(
                    <div className={columnsClassess} key={items.controlId}>
                        <EngagmentSelectPicker
                            formData={formData}
                            value={formData[items.name]}
                            hidden={!items.visible}
                            token={props.token}
                            dspId={props.dspId}
                            items={items}
                            handleChange={handleDropdownChange}
                        />
                    </div>
                );

                if (childPicklistControlIds === null) {
                    setChildPicklistControlIds(items.childPickListControlIds.split(","));
                    setChildPicklistControlName(items.name);
                }
                break;
            case "text":
                itemsToRender.push(
                    <div className={columnsClassess}>
                        <TextField
                            key={"text_field" + index}
                            label={items.label}
                            labelExternal={true}
                            name={items.name}
                            placeholder={items.placeholder}
                            disabled={!items.isEditable}
                            defaultSelected={formData[items.name]}
                            onChange={handleChange}
                        />
                    </div>
                );
                break;
            case "textarea":
                itemsToRender.push(
                    <div className={columnsClassess}>
                        <TextArea
                            key={"text_field" + index}
                            label={items.label}
                            labelExternal={true}
                            name={items.name}
                            placeholder={items.placeholder}
                            disabled={!items.isEditable}
                            defaultSelected={formData[items.name]}
                            onChange={handleChange}
                        />
                    </div>
                );
                break;
            case "selectpicker":
                itemsToRender.push(
                    <div className={columnsClassess} key={items.controlId}>
                        <EngagmentSelectPicker
                            isChild={childPicklistControlIds}
                            childPropName={childPicklistControlName}
                            formData={formData}
                            value={formData[items.name]}
                            hidden={!items.visible}
                            token={props.token}
                            dspId={props.dspId}
                            items={items}
                            handleChange={handleDropdownChange}
                        /></div>
                );
                break;
            case "datepicker":
                itemsToRender.push(
                    <div className={columnsClassess} key={items.controlId}>
                        <TextField
                            key={"text_field" + index}
                            label={items.label}
                            labelExternal={true}
                            name={items.name}
                            type={"date"}
                            placeholder={items.placeholder}
                            defaultSelected={moment(formData[items.name], 'DD/MM/YYYY').format('YYYY-MM-DD')}
                            disabled={!items.isEditable}
                            onChange={handleDateChange}
                        />
                    </div>
                );
                break;
            case "file":

                if (props.data.selectedRecord !== null) {
                    itemsToRender.push(
                        <div className={columnsClassess}>
                            {__renderFileLinks(props.data.selectedRecord[items.name])}
                        </div>
                    );
                }

                if (items.visible === true) {
                    itemsToRender.push(
                        <div className={columnsClassess}>
                        <FileUpload
                            title={items.label}
                            name={items.name}
                            type={items.type}
                            fileTypes={(typeof items.allowedMimeTypesForDocumentUpload === "string" && items.allowedMimeTypesForDocumentUpload !== "") && items.allowedMimeTypesForDocumentUpload.split(",")}
                            data={selectedFiles[items.name]}
                            placeholder={items.placeholder}
                            isMultipleFileUpload={false}
                            onChange={(e: any) => handleChangeFilePciker(e, items.name)}
                        />
                        </div>
                    );
                }

                break;
            default:
                return null;
        }

        return itemsToRender;
    };

    return (
        props?.data?.popupForm?.allowAdd == true || !isEdit ?
            <div className="xan-modal">
                <div
                    className="modal fade show"
                    id="exampleModal"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">
                                    {isEdit ?
                                        t("ADDENGAGEMENTPOPUPHEAD")
                                        :
                                        t("EDITENGAGEMENTPOPUPHEAD")
                                    } {props.title}
                                </h5>
                                <button className="btn btn-transparent close" onClick={() => {
                                    props.onSubmit("", null);
                                }} >
                                    <img src={closeIcon} alt="close" />
                                </button>
                            </div>
                            <form
                                className="information-governance-container"
                                onSubmit={handleFormSubmit}
                            >
                                <div className="modal-body">
                                    {props?.data?.popupForm === undefined && <div style={{ textAlign: "center" }}><img src={infinityLoader} alt="loading" style={{ width: 40 }} /></div>}

                                    <div className="content-container">
                                        
                                        {/* {
                                            props?.data?.popupForm?.controls.length> 0 &&
                                        
                                            <FormWrapper
                                                data={props?.data?.popupForm?.controls}
                                                formData={formData}
                                                type="vertical"
                                                layout="two-column"
                                                onChange={handleChange}
                                                actionButtons={false}
                                            />
                                        } */}

                                        <div className={props?.data?.popupForm?.controls.length < 8 ? "" : "row"}>
                                            {props?.data?.popupForm?.controls?.map((items: any, index: any) => {
                                                if (!items.visible) {
                                                    return;
                                                }
                                                return __renderLayout(items, index, false);
                                            })}
                                        </div> 
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <Button
                                        key={""}
                                        title={"Cancel"}
                                        className="button cancel-button"
                                        type="button"
                                        onClick={() => props.onSubmit("", null)}
                                    />
                                    <Button
                                        key={""}
                                        title={isFetching ? t("SAVING") : props?.data?.popupForm?.controls?.find((item: any) => item.name === "saveButton")?.label || t("SAVE")}
                                        name={props?.data?.popupForm?.controls?.find((item: any) => item.name === "saveButton")?.name}
                                        className={
                                            "button save-button "
                                            // + (isFetching ? "requesting btn-disable" : "")
                                        }
                                        type="submit"
                                    // onClick={handleFormSubmit}
                                    // disabled={props.isEditable || isFetching}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div> : null
    );
};

export default AddEngagmentPopup;
