import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDown from "../atoms/dropDown";

// Functional component for Information Governance
const InformationGovernance = (props: any) => {
    // State for storing the "involve case" data
    const [involveCase, setInvolveCase] = useState<any>(null);
    const [isFetching, setIsFetching] = useState<boolean>(false);

    // State for storing form data
    const [formData, setFormData] = useState<any>(null);

    // UseEffect to extract form data on component mount
    useEffect(() => {
        props.data.controls.map((res: any) => {
            if (res.userConsent) {
                setInvolveCase((prevState: any) => {
                    return {
                        ...prevState,
                        [res.controlId]: { userConsent: res.userConsent }
                    }
                })
            }
            if (res.type === "submit") {
                setFormData(res)
            }
        });
    }, [])

    useEffect(() => {
        setIsFetching(props.isFetching)
    }, [props.isFetching])

    // Function to call API and handle redirection
    const callApiAndRedirect = (e: any): void => {
        if (involveCase !== null && involveCase !== "") {
            const url = formData.apiEndpoint.replace('api', "");
            props.onSubmit("formSubmit", { url, involveCase })
        } else {
            toast.error("Please select an option from the involvement dropdown")
        }
    }

    // Function to render layout based on item type
    const __renderLayout = useCallback((items: any, index: any) => {
        let itemsToRender = [];

        switch (items.type) {
            case "heading2":
                itemsToRender.push(
                    <p className="description" key={items.controlId}>
                        {items.value}
                    </p>
                );
                break;
            case "button":
                itemsToRender.push(
                    <div
                        className="pb-3 d-flex"
                        key={items.controlId}
                    >
                        <button
                            className="btn btn-secondary btn-info-close min-width6 close"
                            key={items.controlId}
                            onClick={() => {
                                props.onSubmit(items.onClick, null);
                            }}
                        >
                            {items.label}
                        </button>
                    </div>
                );
                break;
            case "selectpicker":
                itemsToRender.push(
                    <div className="pb-3">
                        <DropDown
                            label={items.label}
                            labelExternal={true}
                            key={"filters" + index}
                            name={items.name}
                            data={items.picklist.values.map((res: any) => ({ id: res.id, title: res.value, value: res.value }))}
                            defaultSelected={involveCase !== null && involveCase[items.controlId] ? involveCase[items.controlId]?.value : ''}
                            isMultiSelectPickList={items.isMultiSelectPickList}
                            onChange={(resBody: any) => {
                                const userConsent = items?.userConsent ? items.userConsent : null;
                                
                                setInvolveCase((prevState: any) => {
                                    return {
                                        ...prevState,
                                        [items.controlId]: { ...resBody[0], userConsent }
                                    }
                                });
                                
                                // if (involveCase === null) {
                                //     setInvolveCase({
                                //         [items.controlId]: { ...id, userConsent }
                                //     });
                                // } else {
                                //     // update involved case to state if control id is set
                                //     setInvolveCase({
                                //         ...involveCase,
                                //         [items.controlId]: { ...id, userConsent }
                                //     });
                                // }
                            }}
                        /></div>);
                break;
            case "submit":
                let isSelected =
                    involveCase === "" && involveCase === null ? true : false;
                itemsToRender.push(
                    isFetching ? (
                        <button
                            type="button"
                            disabled={true}
                            className={"btn btn-primary min-width6 btn-disabled"}
                            key={items.controlId + "_disabled"}
                        >
                            Requesting
                        </button>
                    ) : (
                        <button
                            type="submit"
                            disabled={isSelected ? true : false}
                            className={
                                "btn btn-primary min-width6 " +
                                (isSelected ? "btn-disabled" : "")
                            }
                            key={items.controlId}
                        >
                            {items.label}
                        </button>
                    )
                );
                break;
            default:
                return null;
        }

        return itemsToRender;
    }, [involveCase, isFetching]);

    return (
        <div className="xan-modal information-governance">
            <div
                className="modal fade show"
                id="exampleModal"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">
                                {props.data.title}
                            </h5>
                        </div>
                        <div className="modal-body">
                            <form
                                className="information-governance-container"
                                onSubmit={(e) => {
                                    e.preventDefault();

                                    if (involveCase === null || involveCase[4] === undefined) {
                                        toast.error("Please select an option from the involvement dropdown");
                                        return 
                                    }

                                    let concentRequire = false;

                                    Object.keys(involveCase).forEach((item: any) => {
                                        if (involveCase[item].userConsent === true && (involveCase[item].id === null || involveCase[item].id === undefined || involveCase[item].id === "No")) {
                                            toast.error("You are not authorised to access OneView");
                                            concentRequire = true;
                                        }
                                    });

                                    if (!concentRequire) {
                                        callApiAndRedirect(e);
                                    }
                                }}
                            >
                                <div className="content-container">
                                    {props.data.controls.map((items: any, index: any) => {
                                        return __renderLayout(items, index);
                                    })}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InformationGovernance;
