import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { pickListEngagmentDataPopup, resetPickEngagmentListData } from "../store/actions";
import { useEffect, useState } from "react";
import { DropDown } from "../../../components";


const EngagmentSelectPicker = (props: any) => {
    const dispatch = useDispatch();

    const pickList = useSelector((state: any) => state.selectEngagmentPickCM);
    const pickListFetching = useSelector((state: any) => state.selectEngagmentPickCM.isFetching);
    
    const [data, setData] = useState([])
    const [isChild, setIsChild] = useState<boolean>(false);

    useEffect(() => {
        // Fetch Information pickList
        try {
            if (props.isChild === null || props.isChild === undefined) {
                dispatch(
                    pickListEngagmentDataPopup({
                        token: props.token,
                        param: {
                            dspId: props.dspId,
                            parentPickListSelectedValue: '',
                            controlId: props.items.controlId,
                        },
                        url: props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""),
                    })
                );
            } else {
                const childData = props.isChild.find((element: any) => parseInt(element) === props.items.controlId);

                if (childData !== undefined) {
                    setIsChild(true);
                } else {
                    dispatch(
                        pickListEngagmentDataPopup({
                            token: props.token,
                            param: {
                                dspId: props.dspId,
                                parentPickListSelectedValue: '',
                                controlId: props.items.controlId,
                            },
                            url: props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""),
                        })
                    );
                }
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, []);

    useEffect(() => {
        // Fetch Information pickList
        try {
            if (isChild && props?.childPropName !== undefined) {
                dispatch(
                    pickListEngagmentDataPopup({
                        token: props.token,
                        param: {
                            dspId: props.dspId,
                            parentPickListSelectedValue: props?.formData[props?.childPropName] == undefined ? "" : props?.formData[props?.childPropName],
                            controlId: props.items.controlId,
                        },
                        url: props.items.apiPicklistEndpoint.endpointUrl.replace("/api", ""),
                    })
                );
            }
        } catch (e: any) {
            toast.error(e.message);
        }
    }, [props?.formData[props?.childPropName], isChild]);

    useEffect(() => {
        if (pickList.isSuccess && pickList.data !== null && props.items.controlId === pickList.data.controlId) {
            setData(pickList.data.values);
            dispatch(resetPickEngagmentListData())
        } else if (pickList.isError) {
            dispatch(resetPickEngagmentListData())
        }
    }, [pickList])



    const parseDataForDropDown = (data: any) => {
        if (data === null || data === undefined) {
            return []
        }

        return data.map((option: any) => { return { "id": option.valueToStore, "title": option.textToDisplay, "value": option.valueToStore } })
    }

    return (
        <div key={props.items.controlId}>
            <DropDown
                label={props.items.label}
                labelExternal={true}
                key={"toolbar"}
                name={props.name}
                disabled={props.formData.CommitmentPresentingNeed === "0" && isChild ? true : false}
                data={parseDataForDropDown(data)}
                defaultSelected={props.value}
                isMultiSelectPickList={false}
                onChange={(e: any) => props.handleChange({ name: props.items.name, value: e[0].value})}
            />
        </div>
    )
}
export default EngagmentSelectPicker;